import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
   path: 'patients',

   loadChildren: () => import('./pages/patients/patients.module').then( m => m.PatientsPageModule)
  },
  {
    path: 'patient-details',

    loadChildren: () => import('./pages/patient-details/patient-details.module').then( m => m.PatientDetailsPageModule)
  },
  {
    path: 'forgotmessage',
    loadChildren: () => import('./pages/forgotmessage/forgotmessage.module').then( m => m.ForgotmessagePageModule)
  },
  {
    path: 'treatments',
    loadChildren: () => import('./pages/treatments/treatments.module').then( m => m.TreatmentsPageModule)
  },
  {
    path: 'observation',
    loadChildren: () => import('./pages/observation/observation.module').then( m => m.ObservationPageModule)
  },
  {
    path: 'product-information',
    loadChildren: () => import('./pages/product-information/product-information.module').then( m => m.ProductInformationPageModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('./pages/administration/administration.module').then( m => m.AdministrationPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'password-change',
    loadChildren: () => import('./pages/password-change/password-change.module').then( m => m.PasswordChangePageModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./pages/clients/clients.module').then( m => m.ClientsPageModule)
  },  {
    path: 'patient-treatments',
    loadChildren: () => import('./pages/patient-treatments/patient-treatments.module').then( m => m.PatientTreatmentsPageModule)
  }


  
  

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
