import { Component } from '@angular/core';
import { Router  } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  [x: string]: any;
  constructor(
    public translate: TranslateService,
    
  ) {
    translate.addLangs(['es']);
    translate.setDefaultLang('es');

    //this.idUser = 135;
  }

  
  
}
